<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="14">
                        <div class="caz-blocks-sarcho-title">
                            <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                                {{ $t("message.o_salary") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search mobile__margintop">
                                    <el-date-picker
                                        v-model="filterForm.from_date"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        size="small"
                                        type="date"
                                        format="dd.MM.yyyy"
                                        value-format="dd.MM.yyyy"
                                        class="mr-3"
                                        placeholder="От"
                                    >
                                    </el-date-picker>
                                    <el-date-picker
                                        v-model="filterForm.to_date"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        size="small"
                                        type="date"
                                        format="dd.MM.yyyy"
                                        value-format="dd.MM.yyyy"
                                        placeholder="До"
                                    >
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            class="mobile__margintop"
                            @c-change="updateColumn"
                            :v_can_create="'TransactionController@store'"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <!-- <div class="w-100 d-flex align-center justify-center">
        <span class="align-items-center align-self-center pr-2">
          <span class="el-tag el-tag--success el-tag--light">
            Общая сумма: 99 704 000 Сум
          </span>
        </span>
      </div> -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>
                            <th v-if="columns.staff.show">
                                {{ columns.staff.title }}
                            </th>
                            <th v-if="columns.paid_amount.show">
                                {{ columns.paid_amount.title }}
                            </th>

                            <th v-if="columns.account.show">
                                {{ columns.account.title }}
                            </th>
                            <th v-if="columns.payment_type.show">
                                {{ columns.payment_type.title }}
                            </th>
                            <th v-if="columns.salary_type.show">
                                {{ columns.salary_type.title }}
                            </th>
                            <th v-if="columns.date_of_end.show">
                                {{ columns.date_of_end.title }}
                            </th>
                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    class="id_input"
                                ></el-input>
                            </th>
                            <th v-if="columns.staff.show">
                                <select-staff
                                    size="mini"
                                    v-model="filterForm.staff_id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.staff.title"
                                ></select-staff>
                            </th>
                            <th v-if="columns.paid_amount.show">
                                <crm-input
                                    :type="'number'"
                                    size="mini"
                                    v-model="filterForm.paid_amount"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.paid_amount.title"
                                ></crm-input>
                            </th>
                            <th v-if="columns.account.show">
                                <select-account
                                    size="mini"
                                    v-model="filterForm.account_id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.account.title"
                                ></select-account>
                            </th>
                            <th v-if="columns.payment_type.show">
                                <el-select
                                    class="w-100"
                                    v-model="filterForm.payment_type"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    size="mini"
                                    :placeholder="$t('message.payment_type')"
                                >
                                    <el-option
                                        v-for="(
                                            type, index
                                        ) in payment_type_list"
                                        :key="'payment_types' + index"
                                        :label="type.label"
                                        :value="type.value"
                                    >
                                    </el-option>
                                </el-select>
                            </th>
                            <th v-if="columns.salary_type.show">
                                <el-select
                                    class="w-100"
                                    v-model="filterForm.salary_type"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    size="mini"
                                    :placeholder="$t('message.salary_type')"
                                >
                                    <el-option
                                        v-for="(salary, index) in salary_types"
                                        :key="'salary-' + index"
                                        :label="salary.label"
                                        :value="salary.value"
                                    ></el-option>
                                </el-select>
                            </th>
                            <th v-if="columns.date_of_end.show">
                                <crm-date-picker
                                    clearable
                                    size="mini"
                                    v-model="filterForm.date_of_end"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.date_of_end.title"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                    size="mini"
                                >
                                </crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                    size="mini"
                                >
                                </crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="transaction in list"
                            :key="'transaction-' + transaction.id"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ transaction.id }} 
                                <!-- <spa></spa> -->
                                <i v-if="transaction.action_key==1" style="font-size:13px; color:#3aa108" class="fa-solid fa-circle-down"></i>
                                <i v-else style="font-size:13px;color:#ff3333" class="fa-solid fa-circle-up"></i>
                            </td>
                            <td v-if="columns.staff.show">
                                {{
                                    transaction.staff
                                        ? transaction.staff.name +
                                          " " +
                                          transaction.staff.last_name
                                        : ""
                                }}
                            </td>
                            <td v-if="columns.paid_amount.show">
                                {{ transaction.paid_amount | formatNumber }}
                            </td>
                            <td v-if="columns.account.show">
                                {{
                                    transaction.account
                                        ? transaction.account.name
                                        : ""
                                }}
                            </td>
                            <td v-if="columns.payment_type.show">
                                {{
                                    transaction.payment_type
                                        ? payment_type(transaction.payment_type)
                                        : ""
                                }}
                            </td>
                            <td v-if="columns.salary_type.show">
                                {{
                                    transaction.salary_type
                                        ? salary_type(transaction.salary_type)
                                        : ""
                                }}
                            </td>
                            <td v-if="columns.date_of_end.show">
                                {{ transaction.date_of_end }}
                            </td>
                            <td v-if="columns.created_at.show">
                                {{ transaction.created_at }}
                            </td>
                            <td v-if="columns.updated_at.show">
                                {{ transaction.updated_at }}
                            </td>
                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="transaction"
                                    :actions="transaction.salary_type == 'salary'?['edit', 'delete' ]:['delete','edit_advance_transaction']"
                                    :permissionShow="'TransactionController@update'"
                                    :permissionDestroy="'TransactionController@delete'"
                                    @edit="edit"
                                    @delete="destroy"
                                    @editAdvanceTransaction="editAdvanceTransaction"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :wrapperClosable="false"
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    size="90%"
                    ref="drawerCreate"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <crm-create
                        ref="drawerCreateChild"
                        drawer="drawerCreate"
                    ></crm-create>
                </el-drawer>
                <el-drawer
                    :with-header="false"
                    :wrapperClosable="false"
                    :visible.sync="drawerUpdate"
                    size="90%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>

            </div>
            <el-drawer
                :wrapperClosable="false"
                :visible.sync="advanceDialogUpdate"
                size="70%"
                :with-header="false"
                ref="advanceDialogUpdate"
                @opened="drawerOpened('advanceDialogUpdateChild')"
                @closed="drawerClosed('advanceDialogUpdateChild')"
              >
                <crm-advance-update 
                  :selectedItem="selectedItem"
                  @closeAdvanceDialog="closeAdvanceDialog"
                  ref="advanceDialogUpdateChild"
                  dialog="advanceDialogUpdate"
                ></crm-advance-update>
              </el-drawer>
        </div>
    </div>
</template>
<script>
import SelectStaff from "@/components/filters/inventory/select-staff";
import SelectAccount from "@/components/filters/inventory/select-account";
import CrmCreate from "./components/crm-create-new";
import CrmUpdate from "./components/crm-update";
import crmAdvanceUpdate from "./components/crm-advance-update";
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    mixins: [list],
    name: "TransactionController",
    components: {
        CrmCreate,
        SelectStaff,
        SelectAccount,
        CrmUpdate,
        crmAdvanceUpdate
    },
    data() {
        return {
            payment_type_list: [
                { value: "card", label: "Пластик" },
                { value: "cash", label: "Наличный" },
            ],
            salary_types: [
                { value: "salary", label: "Зарплата" },
                { value: "additional_salary", label: "Сверхурочные" },
                { value: "advance_salary", label:i18n.t('message.avance')},
                { value: "pentaly_salary", label:i18n.t('message.pentaly_salary')},
            ],
            advanceDialogUpdate:false
        };
    },
    computed: {
        ...mapGetters({
            list: "transaction/list",
            columns: "transaction/columns",
            pagination: "transaction/pagination",
            statues: "transaction/statues",
            filter: "transaction/filter",
            sort: "transaction/sort",
            mode: "MODE"
        }),
        // actions: function () {
        //     return ["edit", "delete", "edit_advance_transaction"];
        // },
    },
    methods: {
        ...mapActions({
            updateList: "transaction/index",
            setPagination: "transaction/setPagination",
            updateSort: "transaction/updateSort",
            updateFilter: "transaction/updateFilter",
            updateColumn: "transaction/updateColumn",
            updatePagination: "transaction/updatePagination",
            show: "transaction/show",
            empty: "transaction/empty",
            delete: "transaction/destroy",
            refreshData: "transaction/refreshData",
        }),
      editAdvanceTransaction(model) {
        this.selectedItem = model;
        this.advanceDialogUpdate = true;
      },
        destroy(model) {
            this.delete(model)
                .then((res) => {
                    this.$alert(res);
                    this.fetchData();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
        payment_type(element) {
            return _.find(this.payment_type_list, function (o) {
                return o.value == element;
            }).label;
        },
        salary_type(element) {
            return _.find(this.salary_types, function (o) {
                return o.value == element;
            }).label;
        },
        closeAdvanceDialog(val) {
          this.advanceDialogUpdate = val;
        },
    },
};
</script>

