<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{ $t("message.new_incoming_payment") }}
          </p>
          <div>
            <!-- <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="closeModal()"
            ></crm-store-update-close> -->
            <el-button
            type="success"
            size="medium"
            @click="submit()"
            
        >
            {{$t('message.save')}}
        </el-button>
        <el-button type="warning" size="medium" @click="closeModal()" plain
        >{{$t('message.close')}}</el-button
        >
          </div>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12" class="mb-3">
                <b>{{$t('message.staff')}}:</b>
                {{
                  form&&form.staff
                    ? form.staff.name + " " + form.staff.last_name
                    : ""
                }}
              </el-col>
              <el-col :span="12" class="mb-3">
                <b>{{$t('message.avance')}}:</b>
                {{selectedItem? Number(selectedItem.paid_amount).toLocaleString("fi-FI"):'' }}               
              </el-col>
              <el-col :span="24" class="mb-3">
                <hr>      
              </el-col>
              <el-col :span="8">
                  <!-- <el-form-item prop="staff_id">
                    <span class="input--label d-block mb-0 line-h-24">
                      <span class="start_top">*</span>
                      {{ $t("message.employees") }}
                    </span>
                    <select-staff
                      :id="form.staff_id"
                      v-model="form.staff_id"
                    ></select-staff>
                  </el-form-item> -->
                <el-form-item prop="currency_id">
                  <span class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.currency") }}
                  </span>
                  <select-currency
                    :id="form.currency_id"
                    v-model="form.currency_id"
                  ></select-currency>
                </el-form-item>
                <el-form-item prop="payment_month">
                  <span class="input--label d-block mb-0 line-h-24 title_data">
                    {{ $t("message.payment_for") }}
                  </span>
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.payment_month"
                    type="month"
                    :format="'MMMM yyyy'"
                    :value-format="'MM.yyyy'"
                    :placeholder="$t('message.payment_for')"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item prop="date_of_end">
                  <span class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.salary") }} {{ $t("message.datas") }}
                  </span>
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.date_of_end"
                    type="date"
                    :picker-options="startAndEndMonthsOptions"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                    :placeholder="
                      $t('message.salary') + ' ' + $t('message.datas')
                    "
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item prop="account_id">
                  <span class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.check") }}
                  </span>
                  <select-account
                    :currency_id="form.currency_id"
                    :id="form.account_id"
                    v-model="form.account_id"
                  ></select-account>
                </el-form-item>
                <!-- <el-form-item prop="paid_amount">
                  <span class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.amount") }}
                  </span>
                  <crm-input
                    :inputValue="form.paid_amount"
                    :placeholder="$t('message.amount')"
                    v-model="form.paid_amount"
                    size="medium"
                    :type="'number'"
                  ></crm-input>
                </el-form-item> -->
              </el-col>

              <el-col :span="8">
                <el-form-item prop="payment_type">
                  <span class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.payment_type") }}
                  </span>
                  <el-select
                    class="w-100"
                    v-model="form.payment_type"
                    size="medium"
                    :placeholder="$t('message.payment_type')"
                  >
                    <el-option
                      v-for="(type, index) in payment_types"
                      :key="'payment_types' + index"
                      :label="type.label"
                      :value="type.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item prop="salary_type">
                  <span class="input--label d-block mb-0 line-h-24">
                    <span class="start_top">*</span>
                    {{ $t("message.salary_type") }}
                  </span>
                  <el-select
                    class="w-100"
                    v-model="form.salary_type"
                    size="medium"
                    :placeholder="$t('message.salary_type')"
                  >
                    <el-option
                      v-for="(salary, index) in salary_types"
                      :key="'salary-' + index"
                      :label="salary.label"
                      :value="salary.value"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
              </el-col>
            </el-row>

            <el-row
              :gutter="20"
              v-if="form.payment_type == 'card'"
              v-loading="loadCard"
            >
              <el-col :span="8">
                <el-form-item>
                  <span
                    class="input--label d-block mb-0 line-h-24 title_data"
                    >{{ $t("message.bank") }}</span
                  >
                  <el-select
                    class="w-100"
                    v-model="form.card_id"
                    size="medium"
                    :placeholder="$t('message.bank')"
                    @change="changeCard"
                  >
                    <el-option
                      v-for="(card, index) in staffCards"
                      :key="'card-' + index"
                      :label="card.card_authority"
                      :value="card.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <span class="input--label d-block mb-0 line-h-24 title_data">{{
                  $t("message.card_number")
                }}</span>

                <crm-input
                  size="medium"
                  :disabled="true"
                  :inputValue="form.card ? form.card.card_number : ''"
                  v-model="form.card_number"
                ></crm-input>
              </el-col>
              <el-col :span="8">
                <span class="input--label d-block mb-0 line-h-24 title_data">
                  {{ $t("message.expiration_date") }}
                </span>

                <crm-input
                  size="medium"
                  :disabled="true"
                  :inputValue="form.card ? form.card.expiry_date : ''"
                  v-model="form.card_expiry_date"
                ></crm-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <span class="input--label d-block mb-0 line-h-24">
                    {{ $t("message.comment") }}
                  </span>
                  <el-input type="textarea" v-model="form.comment"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
        </div>

        <div class="timeline-items__right rounded-sm mt-5 w-100 p-4">
          <el-row :gutter="20">
            <el-col :span="8" :offset="16">
              <div class="card-payment-itme">
                <div class="card-payment-itme-title" style="font-size: 20px">
                  {{ $t("message.total") }} {{ $t("message.on_hands") }}
                </div>
                <div
                  class="card-payment-itme-summa mt-1"
                  style="font-size: 20px"
                >
                  <b>{{$t('message.avance')}}: </b> 
                  <b>{{ parseFloat(form.paid_amount).toLocaleString("fi-FI") }} so'm</b>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import SelectStaff from "@/components/filters/inventory/select-staff";
import SelectCurrency from "@/components/filters/inventory/select-currency";
import SelectAccount from "@/components/filters/inventory/select-account";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import { mapActions, mapGetters } from "vuex";
export default {
  mixins: [drawer, form],
  components: {
    SelectStaff,
    SelectCurrency,
    SelectAccount,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loadingButton: false,
      form: {
        amount: 0,
        penaltyAmount: 0,
        bonus_amount: 0,
        advance_amount: 0,
        amountBeforePenalty: 0,
      },
      payment_types: [
        { value: "card", label: "Пластик" },
        { value: "cash", label: "Наличный" },
      ],
      salary_types: [{ value: "salary", label: "Зарплата" }],
      loadCard: false,
      penaltyAmount: 0,
      bonus_amount: 0,
      isFirst: false,
    };
  },
  watch: {
    "form.payment_type": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
        }
      },
      deep: true,
    },
    "form.staff_id": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
          this.requestInOrder();
        }
      },
      immediate: true,
      deep: true,
    },
     "form.payment_month": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (this.form.payment_type == "card") {
            this.getCard();
          }
          this.requestInOrder();
        }
      },
      deep: true,
    },
    "form.card": {
      handler: function () {},
      deep: true,
    },

    staffStartAndEndDates: {
      handler: function () {},
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      staffCards: "staffTransaction/cards",
      staffSalary: "staffTransaction/salary",
      staffGraphic: "staffTransaction/graphic",
      staffPenalties: "staffTransaction/penalties",
      staffBonuses: "staffTransaction/bonuses",
      staffAdvance: "staffTransaction/advances",
      staffTransactions: "staffTransaction/transactions",
      staffPaymentMonths: "staffTransaction/payment_months",
      staffStartAndEndDates: "staffTransaction/start_and_end_dates",
      rules: "transaction/rules",
      model: "transaction/model",
    }),
    paymentMonthsOptions: function () {
      let paidMonths = this.staffPaymentMonths;
      return {
        disabledDate(time) {
          var date = time.getMonth() + 1 + "." + time.getFullYear();
          if (paidMonths.includes(date)) {
            return true;
          }
          return false;
        },
      };
    },
    startAndEndMonthsOptions: function () {
      let startAndEndMonths = this.staffStartAndEndDates;
      return {
        disabledDate(time) {
          var month = time.getMonth() + 1;
          var date = time.getDate() + "." + month + "." + time.getFullYear();
          if (startAndEndMonths.includes(date)) {
            return true;
          }
          return false;
        },
      };
    },
  },
  methods: {
    ...mapActions({
      getStaffCard: "staffTransaction/getCard",
      getStaffPenalty: "staffTransaction/getPenalty",
      getStaffBonus: "staffTransaction/getBonus",
      getStaffSalary: "staffTransaction/getSalary",
      getStaffAdvance: "staffTransaction/getAdvance",
      getStaffGraphic: "staffTransaction/getGraphic",
      getStaffTransaction: "staffTransaction/getTransaction",
      showTransaction: "transaction/show",
      save: "transaction/update",
      empty: "transaction/empty",
    }),
    async afterOpen() {
      await this.showStaffTransaction();
    },
    async requestInOrder() {
    },
    async showStaffTransaction() {
      if (this.selectedItem.id) {
        await this.showTransaction(this.selectedItem.id)
          .then((res) => {
            this.form = JSON.parse(JSON.stringify(this.model));
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
  
  
    async getCard() {
      if (this.form.staff_id && !this.loadCard) {
        this.loadCard = true;
        await this.getStaffCard(this.form.staff_id)
          .then((res) => {
            this.loadCard = false;
          })
          .catch((err) => {
            this.loadCard = false;
            this.$alert(err);
          });
      }
    },
  
    changeCard(cardId) {
      if (this.staffCards) {
        this.form.card = JSON.parse(
          JSON.stringify(_.find(this.staffCards, ["id", cardId]))
        );
      }
    },
   
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) {
                this.afterLeave();
                this.closeModal();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },

    afterLeave() {
      this.clearForm();
      this.isFirst = false;
    },

    clearForm() {
      this.empty();
      this.form = JSON.parse(
        JSON.stringify({
          paid_amount: 0,
          penalty_amount: 0,
          bonus_amount: 0,
          salary_amount: 0,
        })
      );
    },
    closeModal(val) {
      this.$emit("closeAdvanceDialog", false);
      console.log(val);
    },
  },
};
</script>
